import React, { useEffect, useRef } from "react"
import { PageProps } from "gatsby"
import gsap from "gsap";
import firebase from "gatsby-plugin-firebase"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/gatsby-images/about-page";

import {
  BannerContainer, Title, BannerImageContainer,
  OurGoalContainer, OurGoalLabel, OurGoalDescription,
  ImageShowcaseContainer, MarqueeContainer,
  BenifitsContainer, BenifitsTitle, BenifitsList, BenifitsItem, BenifitTitle, BenifitDesc
} from "../styles/about";

import AboutBg from "../svgs/aboutbg.svg";

const Banner = () => {
  const titleRef = useRef(null);

  useEffect(() => {
    gsap.to(titleRef.current, {
      duration: .5,
      delay: .7,
      opacity: 1,
      y: 0,
      ease: "power3.easeOut"
    });
    gsap.to(".about-bg", {
      duration: .7,
      delay: .3,
      opacity: 1,
      y: 0,
      ease: "power3.easeOut"
    });
  }, []);
  return(
    <BannerContainer>
      <AboutBg className="about-bg" />
      {/* <Title ref={titleRef}>We brew<br />digital experiences<br />using creativity and technology</Title> */}
      <Title ref={titleRef}>We just<br />don't craft products<br />We define EXPERIENCES</Title>
    </BannerContainer>
  )
}

const OurGoal = () => (
  <OurGoalContainer>
    <OurGoalLabel>Our goal</OurGoalLabel>
    <OurGoalDescription>
      With proven expertise in providing pragmatic, sustainable, cost effective and easy to implement solutions, 
      we have successfully carved memorable brands, envisioned astounding digital products and above all, 
      measured our success through happy clients. We share our passion to brew world class apps with 
      remarkable digital experience with our clients. The more complex and challenging a problem is, Easy and Seamless our solution would be.
    </OurGoalDescription>
  </OurGoalContainer>
)

const ImageShowcase = () => (
  <ImageShowcaseContainer>
    <BgMarqueeText />
    <div className="imgContainer img1">
      <Image imageName="about1" />
    </div>
    <div className="imgContainer img2">
      <Image imageName="about2" />
    </div>
    <div className="imgContainer img3">
      <Image imageName="about3" />
    </div>
  </ImageShowcaseContainer>
);

const BgMarqueeText = () => (
  <MarqueeContainer>
    Web apps - Mobile apps - Backend - UI / UX Design
  </MarqueeContainer>
);

const BenifitsOfWorkingWithUs = () => (
  <BenifitsContainer>
    <BenifitsTitle>Why us?</BenifitsTitle>
    <BenifitsList>
      <BenifitsItem className="item1">
        <div className="imgContainer">
          <Image imageName="benifits1" />
        </div>
        <BenifitTitle>Global connect</BenifitTitle>
        <BenifitDesc>We work with clients across the globe with different time zones seamlessly. With an already successful business process, our team has the knack for delivering things, PERFECTLY</BenifitDesc>
      </BenifitsItem>
      <BenifitsItem className="item2">
        <div className="imgContainer">
          <Image imageName="benifits2" />
        </div>
        <BenifitTitle>Full spectrum of services</BenifitTitle>
        <BenifitDesc>Name your business needs, we’re on it. From creating logos, designing, development, product launch, marketing to support, we have always got your back!</BenifitDesc>

      </BenifitsItem>
      <BenifitsItem className="item3">
        <div className="imgContainer">
          <Image imageName="benifits3" />
        </div>
        <BenifitTitle>Complexity is what we love</BenifitTitle>
        <BenifitDesc>“Impossible” simply does not exist in our vocabulary. Products envisioned at the design stage is EXACTLY what is launched. No simplifications, no shortcuts.</BenifitDesc>

      </BenifitsItem>
      <BenifitsItem className="item4">
        <div className="imgContainer">
          <Image imageName="benifits4" />
        </div>
        <BenifitTitle>Flexible work terms</BenifitTitle>
        <BenifitDesc>Every project is unique. Never burn your cash and time with an outdated TnC. Our flexible and customised frameworks are exclusively designed for your project needs and goals.</BenifitDesc>

      </BenifitsItem>
    </BenifitsList>
  </BenifitsContainer>
);

const AboutPage: React.FC<PageProps<any>> = ({ data, path }) => {

  React.useEffect(() => {
    if (firebase) {
      firebase.analytics().logEvent('visited_about_us')
    }
  }, [firebase]);

  return (
    <Layout>
      <SEO title="About Us" />
      <Banner />
      <BannerImageContainer>
        <Image imageName="aboutbanner" />
      </BannerImageContainer>
      <OurGoal />
      <ImageShowcase />
      <BenifitsOfWorkingWithUs />
    </Layout>
  );
}

export default AboutPage
