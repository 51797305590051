import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ imageName }) => {
  const data: any = useStaticQuery(graphql`
    query {
      aboutbanner: file(relativePath: { eq: "about-banner.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 572) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about1: file(relativePath: { eq: "about-1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 572) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about2: file(relativePath: { eq: "about-2.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 572) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about3: file(relativePath: { eq: "about-3.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 572) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      benifits1: file(relativePath: { eq: "benifits1.png" }) {
        childImageSharp {
          fluid(maxHeight: 288, maxWidth: 288) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      benifits2: file(relativePath: { eq: "benifits2.png" }) {
        childImageSharp {
          fluid(maxHeight: 288, maxWidth: 288) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      benifits3: file(relativePath: { eq: "benifits3.png" }) {
        childImageSharp {
          fluid(maxHeight: 288, maxWidth: 288) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      benifits4: file(relativePath: { eq: "benifits4.png" }) {
        childImageSharp {
          fluid(maxHeight: 288, maxWidth: 288) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data[imageName].childImageSharp.fluid) {
    return <div>Picture not found</div>
  }

  return <Img fluid={data[imageName].childImageSharp.fluid} imgStyle={{
    objectFit: "cover"
  }} />
}

export default Image
