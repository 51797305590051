import styled from "styled-components"

export const BannerContainer = styled.div`
  height: calc(100vh - 100px);
  width: 100%;
  padding: 0 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .about-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    opacity: 0;
    transform: translateY(200px);
  }

  @media (max-width: 599px) {
    height: auto;
    padding: 0 25px;
    margin-top: 100px;
  }
  @media (min-width: 600px) {
    height: auto;
    padding: 0 35px;
    margin-top: 100px;
  }
  @media (min-width: 900px) {
    height: calc(100vh - 100px);
    padding: 0 130px;
    margin-top: 0;
  }
`

export const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 500;
  text-align: left;
  opacity: 0;
  transform: translate3d(0, 150px, 0);
  width: 100%;

  @media (max-width: 599px) {
    font-size: 3rem;
  }
  @media (min-width: 600px) {
    font-size: 4.5rem;
  }
  @media (min-width: 900px) {
    font-size: 4.5rem;
  }
`
export const BannerImageContainer = styled.div`
  height: 575px;
  width: calc(100% - 260px);
  margin: 0 130px;
  overflow: hidden;

  @media (max-width: 599px) {
    width: 100%;
    margin: 50px 0;
    height: auto;
  }
  @media (min-width: 600px) {
    width: 100%;
    margin: 50px 0;
    height: auto;
  }
  @media (min-width: 900px) {
    width: calc(100% - 260px);
    margin: 0 130px;
    height: 575px;
  }
`

export const OurGoalContainer = styled.div`
  height: 70vh;
  padding: 0 130px;
  display: flex;
  align-items: center;

  @media (max-width: 599px) {
    flex-direction: column;
    height: auto;
    padding: 0 25px;
  }
  @media (min-width: 600px) {
    flex-direction: column;
    height: auto;
    padding: 0 25px;
  }
  @media (min-width: 900px) {
    flex-direction: row;
    height: 70vh;
    padding: 0 130px;
  }
`
export const OurGoalLabel = styled.div`
  width: 35%;
  text-align: center;
  font-size: 2.25rem;
  font-weight: 500;

  @media (max-width: 599px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media (min-width: 600px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media (min-width: 900px) {
    width: 35%;
    margin-bottom: 0;
  }
`
export const OurGoalDescription = styled.div`
  flex: 1;
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 40px;
`
export const ImageShowcaseContainer = styled.div`
  padding-left: 130px;
  height: 988px;
  position: relative;

  display: grid;
  grid-template-columns: 1.9fr 0.4fr 2.1fr 0.5fr;
  grid-template-rows: 1fr 1fr 1fr 1.2fr 0.8fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . img2 img2"
    ". . img2 img2"
    "img1 . img2 img2"
    "img1 . img2 img2"
    "img1 . . ."
    "img1 . img3 ."
    "img1 . img3 ."
    "img1 . img3 ."
    "img1 . img3 ."
    "img1 . img3 ."
    ". . img3 .";

  .imgContainer {
    display: table;
    overflow: hidden;
  }

  .img1 {
    grid-area: img1;
  }
  .img2 {
    grid-area: img2;
  }
  .img3 {
    grid-area: img3;
  }

  @media (max-width: 599px) {
    display: flex;
    padding-left: 0;
    margin-top: 50px;
    height: auto;
    .img1 {
      width: 100%;
      height: 100vw;
    }
    .img2,
    .img3 {
      display: none;
    }
  }
  @media (min-width: 600px) {
    display: flex;
    padding-left: 0;
    margin-top: 50px;
    height: auto;
    .img2,
    .img3 {
      display: none;
    }
  }
  @media (min-width: 900px) {
    display: grid;
    padding-left: 130px;
    margin-top: 0;
    height: 988px;
    .img2,
    .img3 {
      display: block;
    }
  }
`

export const MarqueeContainer = styled.marquee`
  position: absolute;
  top: -75px;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  font-size: 96px;
  font-weight: 600;
  opacity: 0.2;
  color: #a1a1a1;
`

export const BenifitsContainer = styled.div`
  padding: 50px 130px 50px;

  @media (max-width: 599px) {
    padding: 50px 25px 50px;
  }
  @media (min-width: 600px) {
    padding: 50px 35px 50px;
  }
  @media (min-width: 900px) {
    padding: 50px 130px 50px;
  }
`

export const BenifitsTitle = styled.h4`
  font-size: 36px;
  font-weight: 500;
`

export const BenifitsList = styled.div`
  margin-top: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 40px 40px;
  grid-template-areas:
    "item1 item2"
    "item3 item4";

  .item1 {
    grid-area: item1;
  }
  .item2 {
    grid-area: item2;
  }
  .item3 {
    grid-area: item3;
  }
  .item4 {
    grid-area: item4;
  }

  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 900px) {
    display: grid;
  }
`
export const BenifitsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .imgContainer {
    height: 225px;
    width: 225px;
  }

  @media (max-width: 599px) {
    .imgContainer {
      height: 140px;
      width: 140px;
    }
  }
  @media (min-width: 600px) {
    .imgContainer {
      height: 180px;
      width: 180px;
    }
  }
  @media (min-width: 900px) {
    .imgContainer {
      height: 225px;
      width: 225px;
    }
  }
`

export const BenifitTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: 500;
  max-width: 288px;
  margin-top: 25px;
`
export const BenifitDesc = styled.p`
  font-size: 1rem;
  font-weight: 300;
  max-width: 70%;
  margin-top: 23px;
  line-height: 140%;

  @media (max-width: 599px) {
    max-width: 100%;
  }
  @media (min-width: 600px) {
    max-width: 100%;
  }
  @media (min-width: 900px) {
    max-width: 70%;
  }
`
